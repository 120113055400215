<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Import History</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Program / Import History</span>
      </div>
    </div>
    <div class="card bg-white">
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        :has-header="true"
        @request="onRequestData"
      >
        <template #columns>
          <Column
            field="file_name"
            header="File Name"
            style="width: 190px"
            sortable
          >
            <template #body="{ data }">
              {{ data.file_name }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="FIND FILE NAME"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="file_type"
            header="file type"
            style="width: 190px"
            sortable
          >
            <template #body="{ data }">
              {{ data.file_type }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="FIND FILE TYPE"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="created_at"
            header="import at"
            style="width: 190px"
            sortable
          >
            <template #body="{ data }">
              {{ data.created_at }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="FIND IMPORT AT"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="created_by"
            header="import by"
            style="width: 190px"
            sortable
          >
            <template #body="{ data }">
              {{ data.created_by }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="FIND IMPORT BY"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-download"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onDownload(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
  </div>
</template>

<script>
import ImportHistoryService from '@/services/ImportHistoryService'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Import History',
  components: {
    FxTable,
  },
  data() {
    return {
      selectedColumns: null,
      columns: null,
      importHistoryService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingImport: false,
      roles: [],
      items: [],
      form: {},
      initialValue: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        file_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        file_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_by: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.importHistoryService = new ImportHistoryService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.importHistoryService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Employee', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onDownload(data) {
      this.importHistoryService
        .exportImportHistory(data.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Import History.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Import History', this)
        })
    },
  },
}
</script>
