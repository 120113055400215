<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <template v-if="form.type === 'number' || form.type === 'integer'">
            <InputNumber
              v-model="form.value"
              :class="{ 'p-invalid': v$.form.value.$invalid && submitted }"
              :minFractionDigits="2"
              locale="id-ID"
              class="w-full"
              input-class="w-full"
            />
          </template>
          <template v-if="form.type === 'fraction' || form.type === 'decimal'">
            <InputNumber
              v-model="form.value"
              :class="{ 'p-invalid': v$.form.value.$invalid && submitted }"
              :minFractionDigits="2"
              locale="id-ID"
              class="w-full"
              input-class="w-full"
            />
          </template>
          <template v-if="form.type === 'string'">
            <InputText
              v-model="form.value"
              :class="{ 'p-invalid': v$.form.value.$invalid && submitted }"
              type="text"
              class="w-full"
              input-class="w-full"
            />
          </template>
          <template v-else-if="form.type === 'combobox'">
            <Dropdown
              v-model="form.value"
              :options="form.initial_value"
              :class="{ 'p-invalid': v$.form.value.$invalid && submitted }"
              class="w-full"
              editable
            >
              <template #option="slotProps">
                <div class="p-combobox-label-container">
                  <div class="p-combobox-label">{{ slotProps.option }}</div>
                  <div
                    class="p-combobox-icon-remove pi pi-trash"
                    @click="onRemoveDDItem(slotProps)"
                  ></div>
                </div>
              </template>
            </Dropdown>
          </template>
          <template v-else-if="form.type === 'boolean'">
            <InputSwitch
              v-model="form.value"
              :true-value="true"
              :false-value="false"
            />
          </template>
          <small
            v-if="
              (v$.form.value.$invalid && submitted) ||
              v$.form.value.$pending.$response
            "
            class="p-error"
            >{{ v$.form.value.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Save"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
    onRemoveDDItem(data) {
      const arrInit = this.form.initial_value
      arrInit.splice(data.index, 1)
    },
  },
  validations() {
    return {
      form: {
        value: {
          required: helpers.withMessage('Value is empty.', required),
        },
      },
    }
  },
}
</script>

<style>
.p-combobox-label {
  position: relative;
  width: calc(100% - 24px);
}

.p-combobox-label-container {
  width: 100%;
  position: relative;
}

.p-combobox-icon-remove {
  position: absolute;
  top: 2px;
  right: 0;
  color: red;
}
</style>
