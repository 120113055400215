import http from '../clients/Axios'

export default class SettingService {
  async get(param) {
    param = param ? param : ''
    return await http.get(`/setting${param}`)
  }

  async update(id, form) {
    return await http.post(`/setting/${id}`, form)
  }
}
