import http from '../clients/Axios'

export default class TimeSheetService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/timesheet/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/timesheet/export?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async import(value) {
    let formData = new FormData()
    formData.append('import_file', value)

    return await http.post(`/timesheet/import`, formData)
  }

  async get() {
    const res = await http.get('/timesheet/list')
    return res
  }

  async add(form) {
    const res = await http.post('/employee', form)
    return res
  }

  async update(form) {
    const res = await http.post(`/timesheet/${form.id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/timesheet/${id}`)
    return res
  }
}
