<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Application Settings</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Program / Application Settings</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-10 lg:col-offset-1">
        <div class="card bg-white">
          <grid-setting
            :items="items"
            :loading="isLoading"
            @edit="onEditData"
          />
        </div>
      </div>
    </div>
    <Dialog
      :header="form ? 'Update ' + form.key : ''"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '25vw' }"
      :modal="true"
    >
      <form-setting :item="form" :loading="isLoadingSave" @save="onSaveData" />
    </Dialog>
  </div>
</template>

<script>
import SettingService from '@/services/SettingService'
import FormSetting from '@/components/program/FormSetting'
import errorHandler from '@/helpers/error-handler'
import GridSetting from '@/components/program/GridSetting'

export default {
  name: 'Setting',
  components: {
    GridSetting,
    FormSetting,
  },
  data() {
    return {
      settingService: null,
      dialog: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      form: {},
    }
  },
  created() {
    this.settingService = new SettingService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.settingService
        .get()
        .then((res) => {
          this.items = res.data.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data setting', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onSaveData(data) {
      this.settingService
        .update(data.id, data)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Setting',
              detail: 'Data saved successfully.',
              life: 3000,
            })
            this.loadData()
            this.dialog = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Setting', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
  },
}
</script>
