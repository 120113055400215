<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Timesheet Summary</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Program / Timesheet Summary</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-history"
          label="Import History"
          class="p-button-outlined mr-2"
          @click="$router.push('/program/import-history')"
        />
        <FileUpload
          :loading="isLoadingImport"
          type="file"
          name="file"
          icon="pi pi-upload"
          class="p-button mr-2"
          chooseLabel="Import"
          mode="basic"
          :customUpload="true"
          @uploader="onUpload"
          :auto="true"
          :maxFileSize="10000000"
        />
      </div>
      <DataTable
        ref="grdTimesheet"
        v-model:filters="filters"
        v-model:expandedRows="expandedRows"
        :value="items"
        :row-hover="true"
        :loading="isLoading"
        :paginator="true"
        :rows="10"
        :scrollable="true"
        :lazy="true"
        :totalRecords="totalRecords"
        :rowsPerPageOptions="[10, 25, 50]"
        filterDisplay="row"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} of {last} data from total {totalRecords}"
        class="p-datatable-sm"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        dataKey="ptfi_id"
      >
        <Column style="min-width: 3rem; flex: initial" header="" expander />
        <Column field="ptfi_id" header="ptfi id" style="width: 190px" sortable>
          <template #body="{ data }">
            {{ data.ptfi_id }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND PTF ID"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="ptri_id"
          header="payroll id"
          style="width: 190px"
          sortable
        >
          <template #body="{ data }">
            {{ data.ptri_id }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND PAYROLL ID"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column field="name" header="name" style="width: 190px" sortable>
          <template #body="{ data }">
            {{ data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND NAME"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="department"
          header="department"
          style="width: 190px"
          sortable
        >
          <template #body="{ data }">
            {{ data.department }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND DEPARTEMENT"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="nationality"
          header="nationality"
          style="width: 190px"
          sortable
        >
          <template #body="{ data }">
            {{ data.nationality }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND NATIONALITY"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="timesheet_schedule"
          header="timesheet schedule"
          style="width: 190px"
          sortable
        >
          <template #body="{ data }">
            {{ data.timesheet_schedule }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND SCHEDULE"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column field="country" header="country" style="width: 190px" sortable>
          <template #body="{ data }">
            {{ data.country }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND COUNTRY"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column field="etype" header="e-type" style="width: 190px" sortable>
          <template #body="{ data }">
            {{ data.etype }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND E-TYPE"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="total_hours"
          header="total hours"
          style="width: 190px"
          sortable
        >
          <template #body="{ data }">
            {{ data.total_hours }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND TOTAL HOURS"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="prev_hours"
          header="prev hours"
          style="width: 190px"
          sortable
        >
          <template #body="{ data }">
            {{ data.prev_hours }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND PREV HOURS"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column field="diff_hours" header="diff" style="width: 190px" sortable>
          <template #body="{ data }">
            {{ data.diff_hours }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="FIND DIFF"
              @keydown.enter="filterCallback()"
            />
          </template>
        </Column>
        <Column field="" header="" style="width: 190px" dataType="boolean">
          <template #body="{ data }">
            <div v-if="isShowingCompareData">
              {{ data.compare }}
            </div>
          </template>
          <template #filter>
            <Checkbox v-model="isCompare" binary />
            <label> COMPARE </label>
          </template>
        </Column>
        <template #expansion="{ data }">
          <div class="w-full">
            <div class="col-6" v-if="isCompare === true">
              <DataTable
                v-model:expandedRows="expandedRows1"
                :value="data.child[0]"
                class="p-datatable-sm"
                scrollHeight="400px"
                showGridlines
              >
                <Column field="title" header="" style="min-width: 10rem">
                </Column>
                <Column
                  field="item1"
                  header="01"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item1, data.nationality)"
                    >
                      {{ data.item1 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item2"
                  header="02"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item2, data.nationality)"
                    >
                      {{ data.item2 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item3"
                  header="03"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item3, data.nationality)"
                    >
                      {{ data.item3 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item4"
                  header="04"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item4, data.nationality)"
                    >
                      {{ data.item4 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item5"
                  header="05"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item5, data.nationality)"
                    >
                      {{ data.item5 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item6"
                  header="06"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item6, data.nationality)"
                    >
                      {{ data.item6 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item7"
                  header="07"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item7, data.nationality)"
                    >
                      {{ data.item7 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item8"
                  header="08"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item8, data.nationality)"
                    >
                      {{ data.item8 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item9"
                  header="09"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="getCompareCellStyle(data.item9, data.nationality)"
                    >
                      {{ data.item9 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item10"
                  header="10"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="
                        getCompareCellStyle(data.item10, data.nationality)
                      "
                    >
                      {{ data.item10 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item11"
                  header="11"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="
                        getCompareCellStyle(data.item11, data.nationality)
                      "
                    >
                      {{ data.item11 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item12"
                  header="12"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="
                        getCompareCellStyle(data.item12, data.nationality)
                      "
                    >
                      {{ data.item12 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item13"
                  header="13"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="
                        getCompareCellStyle(data.item13, data.nationality)
                      "
                    >
                      {{ data.item13 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item14"
                  header="14"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="
                        getCompareCellStyle(data.item14, data.nationality)
                      "
                    >
                      {{ data.item14 }}
                    </div>
                  </template>
                </Column>
                <Column
                  field="item15"
                  header="15"
                  :style="getCompareColumnStyle()"
                >
                  <template #body="{ data }">
                    <div
                      :style="
                        getCompareCellStyle(data.item15, data.nationality)
                      "
                    >
                      {{ data.item15 }}
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>

            <div
              :class="
                data.child[1].every(
                  (item) => !item.item16 && item.item16 !== ''
                )
                  ? 'col-8'
                  : 'col-6'
              "
              v-if="isCompare === false"
            >
              <table
                border="1"
                style="
                  border-color: #eeeeee;
                  align-content: center;
                  justify-content: center;
                  text-align: center;
                "
              >
                <tr>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    01
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    02
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    03
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    04
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    05
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    06
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    07
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    08
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    09
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    10
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    11
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    12
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    13
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    14
                  </td>
                  <td :style="getDetailHeaderStyle()" @click="onHeaderClick">
                    15
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item16 !== ''"
                  >
                    16
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item17 !== ''"
                  >
                    17
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item18 !== ''"
                  >
                    18
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item19 !== ''"
                  >
                    19
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item20 !== ''"
                  >
                    20
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item21 !== ''"
                  >
                    21
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item22 !== ''"
                  >
                    22
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item23 !== ''"
                  >
                    23
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item24 !== ''"
                  >
                    24
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item25 !== ''"
                  >
                    25
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item26 !== ''"
                  >
                    26
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item27 !== ''"
                  >
                    27
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item28 !== ''"
                  >
                    28
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item29 !== ''"
                  >
                    29
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item30 !== ''"
                  >
                    30
                  </td>
                  <td
                    :style="getDetailHeaderStyle()"
                    @click="onHeaderClick"
                    v-if="data.child[1][0].item31 !== ''"
                  >
                    31
                  </td>
                </tr>
                <tr>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item1,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item1 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item2,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item2 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item3,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item3 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item4,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item4 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item5,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item5 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item6,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item6 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item7,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item7 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item8,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item8 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item9,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item9 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item10,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item10 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item11,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item11 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item12,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item12 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item13,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item13 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item14,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item14 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item15,
                        data.nationality
                      )
                    "
                  >
                    {{ data.child[1][0].item15 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item16,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item16 !== ''"
                  >
                    {{ data.child[1][0].item16 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item17,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item17 !== ''"
                  >
                    {{ data.child[1][0].item17 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item18,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item18 !== ''"
                  >
                    {{ data.child[1][0].item18 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item19,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item19 !== ''"
                  >
                    {{ data.child[1][0].item19 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item20,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item20 !== ''"
                  >
                    {{ data.child[1][0].item20 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item21,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item21 !== ''"
                  >
                    {{ data.child[1][0].item21 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item22,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item22 !== ''"
                  >
                    {{ data.child[1][0].item22 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item23,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item23 !== ''"
                  >
                    {{ data.child[1][0].item23 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item24,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item24 !== ''"
                  >
                    {{ data.child[1][0].item24 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item25,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item25 !== ''"
                  >
                    {{ data.child[1][0].item25 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item26,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item26 !== ''"
                  >
                    {{ data.child[1][0].item26 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item27,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item27 !== ''"
                  >
                    {{ data.child[1][0].item27 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item28,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item28 !== ''"
                  >
                    {{ data.child[1][0].item28 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item29,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item29 !== ''"
                  >
                    {{ data.child[1][0].item29 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item30,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item30 !== ''"
                  >
                    {{ data.child[1][0].item30 }}
                  </td>
                  <td
                    :style="
                      getDetailCellStyle(
                        data.child[1][0].item31,
                        data.nationality
                      )
                    "
                    v-if="data.child[1][0].item31 !== ''"
                  >
                    {{ data.child[1][0].item31 }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
      </DataTable>
    </div>
    <Dialog
      header="Edit Timesheet"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
      :closable="true"
    >
      <table>
        <tr>
          <td width="70%" style="vertical-align: top">
            <tr>
              <td>
                <tr>
                  <td>TIMELINE</td>
                </tr>
                <tr>
                  <td>TIMELINE Combobox</td>
                </tr>
                <tr>
                  <td>Timesheet Changes</td>
                </tr>
                <tr>
                  <td>Timesheet Changes Datatable</td>
                </tr>
              </td>
            </tr>
          </td>
          <td width="30%" style="vertical-align: top; text-align: right">
            <tr>
              <td style="vertical-align: top; text-align: right">
                <Button
                  icon="pi pi-plus"
                  label="Supporting Documents"
                  class="mr-2"
                />
              </td>
            </tr>
            <tr>
              <td>Supporting Documents Datatable</td>
            </tr>
          </td>
        </tr>
      </table>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Apply Changes"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import TimesheetService from '@/services/TimesheetService'
import errorHandler from '@/helpers/error-handler'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Timesheet',
  data() {
    return {
      expandedRows: [],
      expandedRows1: [],
      expandedRows2: [],
      selectedColumns: null,
      columns: null,
      timesheetService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingImport: false,
      roles: [],
      items: [],
      form: {},
      initialValue: {},
      totalRecords: 0,
      isCompare: false,
      isShowingCompareData: false,
      options: {
        page: 1,
        rows: 10,
        sortField: 'name',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        ptfi_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ptri_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nationality: { value: null, matchMode: FilterMatchMode.CONTAINS },
        timesheet_schedule: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        country: { value: null, matchMode: FilterMatchMode.CONTAINS },
        etype: { value: null, matchMode: FilterMatchMode.CONTAINS },
        total_hours: { value: null, matchMode: FilterMatchMode.CONTAINS },
        prev_hours: { value: null, matchMode: FilterMatchMode.CONTAINS },
        diff_hours: { value: null, matchMode: FilterMatchMode.CONTAINS },
        compare: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.timesheetService = new TimesheetService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.filters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.timesheetService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data

          this.items.forEach((element) => {
            element.child = []

            var compare_parse = []
            if (element.compare[0] !== undefined) {
              if (element.compare[0] !== null) {
                if (element.compare[0].stage_1 !== undefined) {
                  if (element.compare[0].stage_1 !== null) {
                    compare_parse.push({
                      title: 'Period #1 (1-15)',
                      nationality: element.nationality,
                      item1: element.compare[0].stage_1.t_01,
                      item2: element.compare[0].stage_1.t_02,
                      item3: element.compare[0].stage_1.t_03,
                      item4: element.compare[0].stage_1.t_04,
                      item5: element.compare[0].stage_1.t_05,
                      item6: element.compare[0].stage_1.t_06,
                      item7: element.compare[0].stage_1.t_07,
                      item8: element.compare[0].stage_1.t_08,
                      item9: element.compare[0].stage_1.t_09,
                      item10: element.compare[0].stage_1.t_10,
                      item11: element.compare[0].stage_1.t_11,
                      item12: element.compare[0].stage_1.t_12,
                      item13: element.compare[0].stage_1.t_13,
                      item14: element.compare[0].stage_1.t_14,
                      item15: element.compare[0].stage_1.t_15,
                    })
                  }
                }
              }
            }

            if (element.compare[0] !== undefined) {
              if (element.compare[0] !== null) {
                if (element.compare[0].stage_2 !== undefined) {
                  if (element.compare[0].stage_2 !== null) {
                    compare_parse.push({
                      title: 'Period #2 (16-31)',
                      nationality: element.nationality,
                      item1: element.compare[0].stage_2.t_01,
                      item2: element.compare[0].stage_2.t_02,
                      item3: element.compare[0].stage_2.t_03,
                      item4: element.compare[0].stage_2.t_04,
                      item5: element.compare[0].stage_2.t_05,
                      item6: element.compare[0].stage_2.t_06,
                      item7: element.compare[0].stage_2.t_07,
                      item8: element.compare[0].stage_2.t_08,
                      item9: element.compare[0].stage_2.t_09,
                      item10: element.compare[0].stage_2.t_10,
                      item11: element.compare[0].stage_2.t_11,
                      item12: element.compare[0].stage_2.t_12,
                      item13: element.compare[0].stage_2.t_13,
                      item14: element.compare[0].stage_2.t_14,
                      item15: element.compare[0].stage_2.t_15,
                    })
                  }
                }
              }
            }

            if (element.compare[0] !== undefined) {
              if (element.compare[0] !== null) {
                if (element.compare[0].result !== undefined) {
                  if (element.compare[0].result !== null) {
                    compare_parse.push({
                      title: 'RESULT',
                      nationality: element.nationality,
                      item1: String(element.compare[0].result.t_01),
                      item2: String(element.compare[0].result.t_02),
                      item3: String(element.compare[0].result.t_03),
                      item4: String(element.compare[0].result.t_04),
                      item5: String(element.compare[0].result.t_05),
                      item6: String(element.compare[0].result.t_06),
                      item7: String(element.compare[0].result.t_07),
                      item8: String(element.compare[0].result.t_08),
                      item9: String(element.compare[0].result.t_09),
                      item10: String(element.compare[0].result.t_10),
                      item11: String(element.compare[0].result.t_11),
                      item12: String(element.compare[0].result.t_12),
                      item13: String(element.compare[0].result.t_13),
                      item14: String(element.compare[0].result.t_14),
                      item15: String(element.compare[0].result.t_15),
                    })
                  }
                }
              }
            }
            element.child.push(compare_parse)

            var detail_parse = []
            if (element.detail !== undefined) {
              if (element.detail !== null) {
                detail_parse = [
                  {
                    nationality: element.nationality,
                    item1: element.detail.t_01,
                    item2: element.detail.t_02,
                    item3: element.detail.t_03,
                    item4: element.detail.t_04,
                    item5: element.detail.t_05,
                    item6: element.detail.t_06,
                    item7: element.detail.t_07,
                    item8: element.detail.t_08,
                    item9: element.detail.t_09,
                    item10: element.detail.t_10,
                    item11: element.detail.t_11,
                    item12: element.detail.t_12,
                    item13: element.detail.t_13,
                    item14: element.detail.t_14,
                    item15: element.detail.t_15,
                    item16: element.detail.t_16,
                    item17: element.detail.t_17,
                    item18: element.detail.t_18,
                    item19: element.detail.t_19,
                    item20: element.detail.t_20,
                    item21: element.detail.t_21,
                    item22: element.detail.t_22,
                    item23: element.detail.t_23,
                    item24: element.detail.t_24,
                    item25: element.detail.t_25,
                    item26: element.detail.t_26,
                    item27: element.detail.t_27,
                    item28: element.detail.t_28,
                    item29: element.detail.t_29,
                    item30: element.detail.t_30,
                    item31: element.detail.t_31,
                  },
                ]
              }
            }
            element.child.push(detail_parse)
          })

          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Timesheet', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onExportToExcel() {
      this.isLoading = true
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.timesheetService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Employee-Management.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Timesheet Summary', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onUpload(event) {
      this.isLoadingImport = true
      this.timesheetService
        .import(event.files[0])
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Import File Timesheet Summary',
              detail: 'Data imported successfully.',
              life: 3000,
            })
            this.loadData()
          } else {
            let msg = []
            res.data.data.forEach((value) => {
              msg.push(value.error)
            })
            this.$toast.add({
              severity: 'error',
              summary: `${res.data.message}`,
              detail: `${msg}`,
              life: 4000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Import File Timesheet Summary', this)
        })
        .finally(() => (this.isLoadingImport = false))
    },
    getCompareColumnStyle() {
      return {
        'justify-content': 'center',
        padding: 0,
        margin: 0,
        display: 'flex',
      }
    },
    getCompareCellStyle(data, nationality) {
      // console.log(data)
      // console.log(nationality)

      var color = 'white'
      if (nationality === 'National') {
        if (data === 'V') {
          color = '#fac9da'
        } else if (data === 'UP') {
          color = 'orange'
        } else if (data === 'P1K') {
          color = 'yellow'
        } else if (data === 'B') {
          color = '#c6daf3'
        } else if (data === 'P1') {
          color = '#eeeeee'
        } else {
          color = 'white'
        }
      }
      if (nationality === 'Expat') {
        if (data === 'V') {
          color = 'yellow'
        } else if (data === 'UP') {
          color = '#c6daf3'
        } else if (data === 'P1K') {
          color = '#fbc02d'
        } else if (data === 'S') {
          color = '#d4ecd5'
        } else if (data === 'R') {
          color = 'white'
          return {
            'background-color': color,
            flex: 1,
            height: '30px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'font-weight': 'bold',
          }
        } else {
          color = 'white'
        }
      }

      return {
        'background-color': color,
        flex: 1,
        height: '30px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
      }
    },
    getDetailHeaderStyle() {
      return {
        padding: '10px',
        margin: '10px',
        color: '#4081d5',
        'font-weight': 'bold',
        cursor: 'pointer',
        'background-color': 'white',
      }
    },
    getDetailCellStyle(data, nationality) {
      // console.log(data)
      // console.log(nationality)

      var color = 'white'
      if (nationality === 'National') {
        if (data === 'V') {
          color = '#fac9da'
        } else if (data === 'UP') {
          color = 'orange'
        } else if (data === 'P1K') {
          color = 'yellow'
        } else if (data === 'B') {
          color = '#c6daf3'
        } else if (data === 'P1') {
          color = '#eeeeee'
        } else {
          color = 'white'
        }
      }
      if (nationality === 'Expat') {
        if (data === 'V') {
          color = 'yellow'
        } else if (data === 'UP') {
          color = '#c6daf3'
        } else if (data === 'P1K') {
          color = '#fbc02d'
        } else if (data === 'S') {
          color = '#d4ecd5'
        } else if (data === 'R') {
          color = 'white'
          return {
            'background-color': color,
            padding: '10px',
            margin: '10px',
            color: 'black',
            'font-weight': 'bold',
          }
        } else {
          color = 'white'
        }
      }

      return {
        'background-color': color,
        padding: '10px',
        margin: '10px',
        color: 'black',
      }
    },
    onHeaderClick(data) {
      this.form = Object.assign({}, data)
      this.initialValue = Object.assign({}, data)
      this.dialog = true
    },
  },
}
</script>
