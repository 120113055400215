<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-4">
          <label :class="{ 'p-error': v$.form.ptri_id.$invalid && submitted }"
            >PTRI_ID *</label
          >
          <InputText
            v-model="form.ptri_id"
            :class="{ 'p-invalid': v$.form.ptri_id.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.ptri_id.$invalid && submitted) ||
              v$.form.ptri_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.ptri_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label :class="{ 'p-error': v$.form.ptfi_id.$invalid && submitted }"
            >PTFI_ID *</label
          >
          <InputText
            v-model="form.ptfi_id"
            :class="{ 'p-invalid': v$.form.ptfi_id.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.ptfi_id.$invalid && submitted) ||
              v$.form.ptfi_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.ptfi_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label :class="{ 'p-error': v$.form.name.$invalid && submitted }"
            >Name *</label
          >
          <InputText
            v-model="form.name"
            :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.name.$invalid && submitted) ||
              v$.form.name.$pending.$response
            "
            class="p-error"
            >{{ v$.form.name.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label>Group Occupation</label>
          <Dropdown
            v-model="form.group_occupation"
            :options="['Miner', 'Surveyor']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Occupation</label>
          <Dropdown
            v-model="form.occupation"
            :options="['Miner\/Operator', 'Surveyor']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Departement</label>
          <Dropdown
            v-model="form.department"
            :options="['GBC-1', 'GBC-2']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Nationality</label>
          <Dropdown
            v-model="form.nationality"
            :options="['expat', 'national']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Country</label>
          <Dropdown
            v-model="form.country"
            :options="['Indonesia', 'Canada']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Employee Type *</label>
          <Dropdown
            v-model="form.etype"
            :options="['staff', 'hourly']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Grade</label>
          <Dropdown
            v-model="form.grade"
            :options="['B1', 'B3']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Marrital Status</label>
          <Dropdown
            v-model="form.marrital_status"
            :options="['M/1', 'M/2']"
            class="w-full"
            editable
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Location</label>
          <Dropdown
            v-model="form.location"
            :options="['Tembagapura', 'Jakarta']"
            class="w-full"
            editable
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Save"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      form: this.item ? this.item : {},
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Add Employee' : 'Edit Employee'
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        ptri_id: {
          required: helpers.withMessage('PTRI_ID is empty.', required),
        },
        ptfi_id: {
          required: helpers.withMessage('PTFI_ID is empty.', required),
        },
        name: {
          required: helpers.withMessage('Name is empty.', required),
        },
        etype: {
          required: helpers.withMessage('Employee Type is empty.', required),
        },
      },
    }
  },
}
</script>
