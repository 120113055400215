<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.name.$invalid && submitted }"
            >Name *</label
          >
          <InputText
            v-model="form.name"
            :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.name.$invalid && submitted) ||
              v$.form.name.$pending.$response
            "
            class="p-error"
            >{{ v$.form.name.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.username.$invalid && submitted }"
            >Username *</label
          >
          <InputText
            v-model="form.username"
            :class="{ 'p-invalid': v$.form.username.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.username.$invalid && submitted) ||
              v$.form.username.$pending.$response
            "
            class="p-error"
            >{{ v$.form.username.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label>Password *</label>
          <Password
            v-model="form.password"
            :toggleMask="true"
            class="w-full"
            input-class="w-full"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label :class="{ 'p-error': v$.form.email.$invalid && submitted }"
            >Email *</label
          >
          <InputText
            v-model="v$.form.email.$model"
            type="text"
            class="w-full"
            :class="{ 'p-invalid': v$.form.email.$invalid && submitted }"
          />
          <span v-if="v$.form.email.$error && submitted">
            <span
              id="email-error"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small
            v-else-if="
              (v$.form.email.$invalid && submitted) ||
              v$.form.email.$pending.$response
            "
            class="p-error"
            >{{ v$.form.email.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label :class="{ 'p-error': v$.form.phone.$invalid && submitted }"
            >PHONE *</label
          >
          <InputText v-model="form.phone" class="w-full" />
          <small
            v-if="
              (v$.form.phone.$invalid && submitted) ||
              v$.form.phone.$pending.$response
            "
            class="p-error"
            >{{ v$.form.phone.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label>Role</label>
          <Dropdown
            v-model="form.type"
            :options="['admin', 'hrd', 'manager']"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Status</label>
          <Dropdown
            v-model="form.status"
            :options="['active', 'inactive']"
            class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Save"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'
import Password from 'primevue/password'

export default {
  components: {
    Password,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      form: this.item ? this.item : {},
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Add user' : 'Edit user'
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Name is empty.', required),
        },
        username: {
          required: helpers.withMessage('Username is empty.', required),
        },
        email: {
          required: helpers.withMessage('Email is empty.', required),
          email: helpers.withMessage('Email Format is not valid.', email),
        },
        phone: {
          required: helpers.withMessage('Phone numbers is empty.', required),
        },
      },
    }
  },
}
</script>
